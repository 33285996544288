// preview css
.feature-section {
  background: #fff;
  .feature-box-01 {
    background: #fff;
    border: 1px solid #efefef;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.05);
    p,
    h5 {
      color: #212529;
    }
    p {
      font-size: 16px;
    }
    &::before {
      background: #fff;
    }
    &::after {
      background: #f7f7ff;
      transition: 0.5s ease-in-out;
    }
    &:hover {
      h5 {
        color: #212529;
      }
      border-color: #f7f7f7;
      .icon {
        img {
          background: #f7f7f7;
        }
      }
    }
  }
}
.preview-sticker h6 {
  color: #fff;
  font-size: 14px;
}
.preview-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f7f7ff;
  // @media screen and (max-width: 767px) {
  //   height: calc(100vh - 56px) !important;
  //   @supports (-webkit-appearance: none) {
  //     .os-android & {
  //       height: calc(100vh - 56px) !important;
  //       min-height: calc(100vh - 56px) !important;
  //     }
  //   }
  // }
  @media screen and (max-width: 360px) {
    height: 100vh !important;
  }
}

.preview-inner {
  @media screen and (min-width: 1920px) {
    margin-left: 35px;
  }
}
.demo-section {
  @media screen and (min-width: 1920px) {
    .container {
      max-width: 1440px;
      padding-bottom: 0;
    }
  }
  .title {
    margin-top: 25px;
    h3 {
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  .single-demo {
    transition: 0.3s;
    .title h3 {
      font-size: 26px;
    }

    &:hover {
      transform: translateY(-15px);
    }
  }
  .portfolio-filter-01 {
    padding-top: 0;
  }

  .slick-dots {
    bottom: -40px;

    @media screen and (min-width: 1920px) {
      margin-left: -12px;
    }
  }
}

.feature-section {
  .container-fluid {
    padding-left: 150px;
    padding-right: 150px;
    @media screen and (max-width: 1499px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .feature-box-01 {
    height: 100%;
    h5 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    .icon {
      img {
        width: 55px;
        height: 55px;
        margin-bottom: 30px;
        background: hsla(0, 0%, 100%, 1);
        padding: 10px;
        border-radius: 50%;
        transition: 0.5s;
      }
    }
    &:hover {
      .icon,
      h5,
      p {
        animation: slideTop 0.7s ease-in-out 0s 1 normal none running !important;
      }
    }
  }
}
.title-wrapper {
  .title {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    margin-top: 0;
    margin-bottom: 40px;
    @media screen and (max-width: 1499px) {
      font-size: 30px;
    }
    .theme-color {
      color: $px-theme;
    }
  }
  .tag {
    background: #ff9301;
    color: #fff;
  }
}
.tag {
  display: inline-block;
  background: #1c1d24;
  padding: 0 10px;
  color: $px-theme;
  border-radius: 4px;
  margin-bottom: 10px;
}
.purchase-block-inner {
  h3 {
    max-width: 600px;
    font-weight: 600;
    margin: 0 auto;
    font-size: 35px;
    display: block;
    padding: 10px 0 30px;
    letter-spacing: 1px;
    line-height: 45px;
    color: #000;
    @media screen and (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  h6 {
    font-weight: 400;
  }

  .theme-color {
    color: $px-theme;
  }
  .dark-color {
    font-size: 17px;
    font-weight: 500;
    display: inline-block;
  }
  @media screen and (min-width: 1200px) {
    .px-btn {
      font-size: 18px;
      line-height: 52px;
      letter-spacing: 1px;
      padding: 0 30px;
    }
  }
}
.preview-inner {
  .filter {
    padding-left: 0;
    flex-wrap: wrap;
    background: #fff5ec;
    max-width: 408px;
    margin: auto;
    border-radius: 10px;
    padding: 10px 40px;
    margin-bottom: 23px;
  }
}
.preview-header {
  .px-btn {
    line-height: 52px;
    text-transform: uppercase;

    @media screen and (max-width: 767px) {
      line-height: 42px;
      margin-bottom: 5px;
    }
  }
  h3 {
    font-size: 35px;
    margin: 30px 0 15px;
    color: #fff;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 20px;
      margin-top: 20px;
      line-height: 1.3;
    }
  }
  .theme-color {
    font-size: 40px;
    font-weight: 800;
    line-height: 1;
  }
  .lead {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 25px;
    color: rgba(255, 255, 255, 0.9);
  }
  .navbar-brand {
    @media screen and (max-width: 767px) {
      height: 32px;
    }
  }
}

.purchase-section,
section.testimonial,
.footer {
  overflow: hidden;
}
.purchase-section {
  background: #f7f7ff;
}
// demo light to dark switch
.demo-toggler {
  width: 25px;
  position: fixed;
  z-index: 99;
  right: 0px;
  top: calc(50% - 65px);
  .toggler-btn {
    border-radius: 4px 0 0 4px;
    background: $px-theme;
    border: 2px solid $px-theme;
    color: #fff;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    position: relative;
    z-index: 2;
    font-size: 14px;
    width: 100%;
    min-width: 100%;
    padding: 10px 0px 8px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      background: #fff5ec;
      a {
        color: #000;
      }
    }
    a {
      color: #fff;
    }
  }
  .inner-text {
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.section.inner-pages-wrapper {
  background: #000;
}

.preview-sticker {
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1d24;
  color: #fff;
  font-weight: 400 !important;
  font-size: 16px;
  flex-direction: column;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  line-height: 1.1;
  padding: 20px 0;
  img {
    max-width: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 80px;
    font-size: 20px;
  }
  .price {
    font-weight: 700;
    font-size: 30px;
  }
  &.price-sticker {
    background: #ff9301;
    color: #fff;
    width: 80px;
    height: 80px;
    top: auto;
    left: auto;
    right: 20px;
    bottom: 20px;
    @media screen and (max-width: 767px) {
      right: 15px;
      bottom: 15px;
    }
  }
  &.react-sticker {
    border-radius: 0px 0px 80px 80px;
    @media screen and (max-width: 767px) {
      img {
        max-width: 55%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .preview-section {
    padding: 0 5px;
  }
  p {
    br {
      display: none;
    }
  }
}
