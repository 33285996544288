/* Bg color
----------------------------*/
.dark-bg {
  background: $px-bg-2;
  .theme-light & {
    background: $px-gray;
  }
}
.light-bg {
  background: $px-gray;
  .theme-light & {
    background: $px-bg-2;
  }
}
